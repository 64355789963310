.Product-filters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px; }

.ProductsList {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  margin: 0 0 40px 0;
  padding: 40px 0; }

.Filters-wrapper {
  background: #2c2c2a;
  border: 1px solid #474745;
  border-radius: 10px;
  margin: 20px -20px 0 -20px;
  padding: 20px; }

.ResetFilters-button {
  background: #e84e1a;
  border: 1px solid #e84e1a;
  color: #fff;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px; }
  .ResetFilters-button .icon {
    font-size: 1.75em;
    fill: #fff;
    color: #fff;
    margin-right: 5px; }
  .ResetFilters-button:hover {
    background: #e84e1a;
    color: #fff;
    opacity: 0.8; }
    .ResetFilters-button:hover .icon {
      fill: #fff;
      color: #fff; }

.ProductsList-no-results {
  margin-top: 40px; }
  .ProductsList-no-results h2 {
    margin: 0 0 20px 0; }
