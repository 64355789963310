.ModalDialog {
  background: rgba(29, 29, 27, 0.9);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  z-index: 1050;
  -webkit-overflow-scrolling: touch; }

.ModalDialog-wrapper {
  height: 80vh;
  width: 80vh; }
  .ModalDialog-wrapper img {
    background: #fff;
    padding: 20px;
    height: 100%;
    width: 100%; }

.ModalDialog-content {
  position: relative; }

.ModalDialog-close {
  background: transparent;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  z-index: 500; }
  .ModalDialog-close .icon {
    font-size: 2em;
    color: #1d1d1b;
    fill: #1d1d1b; }
  .ModalDialog-close:hover {
    background: transparent; }
    .ModalDialog-close:hover .icon {
      opacity: 0.7; }
