.SelectField-wrapper {
  display: flex;
  flex-flow: column nowrap;
  position: relative; }
  .SelectField-wrapper label {
    font-size: 1.6rem;
    color: #fff;
    margin-bottom: 5px; }

.SelectField {
  appearance: none;
  background: #fff;
  border: 1px solid #1d1d1b;
  border-radius: 5px;
  color: #1d1d1b;
  cursor: pointer;
  padding: 10px;
  position: relative;
  outline: none; }
  .SelectField[disabled] {
    opacity: 0.5; }

.SelectField-arrow {
  color: #1d1d1b;
  pointer-events: none;
  margin-top: 6px;
  position: absolute;
  top: 50%;
  right: 10px; }

option[disabled] {
  color: #bdbcbc; }
