.ScannerPage-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px); }

.ScannerPage-title,
.ScannerPage-body {
  text-align: center; }

.Scanner-explanation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 40px 0; }
  .Scanner-explanation li {
    text-align: center; }
    .Scanner-explanation li h2 {
      margin: 10px 0; }

.explanation-icon {
  background: #2a2a29;
  border: none;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5em;
  margin: 0 auto;
  position: relative;
  text-align: center;
  height: 2em;
  width: 2em; }
  .explanation-icon .icon-hand {
    animation: ease moveBackAndForth 2s infinite;
    transform: rotate(45deg); }
  .explanation-icon .icon-minus {
    animation: ease-in-out pulse 2s infinite;
    color: red;
    position: absolute;
    margin-top: -0.6em;
    margin-left: -0.6em;
    top: 50%;
    left: 50%;
    height: 1.2em;
    width: 1.2em; }
  .explanation-icon .icon-checkmark {
    animation: linear pausedScaleIn 4s infinite;
    color: green;
    opacity: 1; }
  .explanation-icon .icon-spinner3 {
    animation: linear pausedSpin 4s infinite;
    color: #fff;
    position: absolute;
    margin-top: -0.5em;
    margin-left: -0.5em;
    opacity: 0;
    top: 50%;
    left: 50%;
    height: 1em;
    width: 1em; }

@keyframes moveBackAndForth {
  0% {
    transform: rotate(30deg); }
  50% {
    transform: rotate(-30deg); }
  100% {
    transform: rotate(30deg); } }

@keyframes pulse {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.45; }
  100% {
    opacity: 0; } }

@keyframes pausedSpin {
  0% {
    opacity: 1;
    transform: rotate(0deg); }
  25% {
    opacity: 1;
    transform: rotate(180deg); }
  50% {
    opacity: 0;
    transform: rotate(360deg); }
  75% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes pausedScaleIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  50% {
    opacity: 0;
    transform: scale(0); }
  75% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.ScannerPage-body {
  margin: 20px 0; }
  .ScannerPage-body li + li {
    margin-top: 0.334em; }
  .ScannerPage-body ol {
    list-style: decimal;
    padding-left: 20px; }
