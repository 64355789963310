html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0; }

ul,
ol {
  margin: 0;
  padding-left: 0;
  list-style: none; }
