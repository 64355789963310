html {
  background: #1d1d1b;
  min-height: 100vh;
  font-size: 62.5%; }

body {
  color: #fff;
  font-size: 1.4rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
  line-height: 1.5; }

strong {
  font-weight: 700; }

h1,
h2 {
  font-size: 3.2rem;
  line-height: 1.2;
  font-weight: 300;
  -webkit-font-smoothing: antialiased; }

h3,
h4 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 300;
  -webkit-font-smoothing: antialiased; }

h5 {
  font-size: 1.4rem;
  font-weight: 300; }

.preamble {
  font-size: 1.6rem;
  font-weight: 300; }

a {
  color: #fff; }

.content-flow > * + * {
  margin-top: 1em; }

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative; }
  .container.no-gutter {
    padding-left: 0;
    padding-right: 0; }

hr {
  background: #bdbcbc; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }
