.BrandList {
  border-top: 1px solid #bdbcbc;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  margin: 30px 0 40px 0;
  padding: 40px 0 0 0; }

.Brand-item {
  background: #fff;
  border-radius: 20px;
  color: #1d1d1b;
  position: relative;
  overflow: hidden;
  text-align: center; }
  .Brand-item a {
    color: #1d1d1b;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 2rem;
    text-decoration: none;
    height: 100%;
    width: 100%; }
  .Brand-item:hover {
    opacity: 0.9; }
  .Brand-item img {
    border-radius: 20px;
    display: block;
    max-width: 100%;
    height: auto; }
  .Brand-item .product-count {
    color: #1d1d1b;
    position: absolute;
    top: 10px;
    right: 10px; }
