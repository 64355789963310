.Product {
  padding-bottom: 60px; }

.Product-content {
  width: 66.6667%; }

.Product-title {
  font-size: 2.6rem;
  margin-bottom: 15px; }

.Product-title-lang {
  display: inline-block; }

.Product-title-lang:nth-child(2) {
  font-style: italic; }
  .Product-title-lang:nth-child(2):before {
    display: inline-block;
    content: '/';
    margin: 0 10px; }

.Product-flag {
  flex: 0 0 1em;
  margin: 0.1em 0.2em 0 0;
  height: 1em;
  width: 1em; }

.Product-content {
  margin-bottom: 20px; }

.Product-details {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 40px; }

.Product-sub-title {
  border-top: 1px solid #fff;
  padding-top: 15px; }

.Product-design {
  padding-bottom: 10px; }
  .Product-design .LazyLoad {
    background: #fff;
    padding: 20px; }

.Product-img-wrapper {
  margin-top: 20px;
  position: relative;
  overflow: hidden; }

.Product-info-list,
.Product-table-of-contents {
  color: #fff;
  font-size: 1.4rem;
  margin-top: 20px; }

.Product-info-list li + li {
  margin-top: 5px; }
