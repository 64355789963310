.Spinner {
  position: relative;
  height: 80px;
  width: 100%; }
  .Spinner .icon {
    animation: spin 1s infinite linear;
    font-size: 2.5em;
    fill: #fff;
    color: #fff;
    margin-left: -0.5em;
    margin-top: -0.5em;
    transform-origin: center;
    perspective: 250px;
    transform-style: preserve-3d;
    position: absolute;
    top: 50%;
    left: 50%; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }
