.PriceList {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px; }
  .PriceList > li + li {
    margin-top: 15px; }

.PriceItem {
  background: linear-gradient(to left, rgba(142, 158, 171, 0), rgba(238, 242, 243, 0.08));
  color: #fff;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 100px;
  grid-column-gap: 15px; }
  .PriceItem:first-child {
    background: linear-gradient(to left, rgba(142, 158, 171, 0), rgba(238, 242, 243, 0.3)); }
    .PriceItem:first-child .Price-cost {
      font-size: 2rem; }
  .PriceItem img {
    background: #fff;
    grid-row: span 2;
    padding: 5px; }
  .PriceItem span {
    font-size: 1.6rem; }

.PriceItem-thumbnail-preview {
  color: #1d1d1b;
  cursor: pointer;
  position: relative; }
  .PriceItem-thumbnail-preview .icon {
    position: absolute;
    top: 5px;
    left: 5px; }

.PriceItem-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center; }

.Price-cost {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  color: #f47c5e; }
  .Price-cost strong {
    margin: 0 0.225em; }
  .Price-cost .icon-price-tag {
    color: #f47c5e;
    fill: #f47c5e; }
