.Scanner {
  margin-left: -340px;
  position: fixed;
  opacity: 0;
  bottom: 0;
  left: 50%;
  transition: opacity 300ms linear;
  width: 680px;
  z-index: 1000; }
  .Scanner.animate-in {
    opacity: 1; }
  .Scanner .icon {
    background: rgba(29, 29, 27, 0.5);
    border-radius: 100%;
    padding: 5px; }

.Scanner-Error {
  background: #f47c5e;
  border: 1px solid #f47c5e;
  color: #fff;
  margin: 0;
  padding: 20px;
  text-align: center; }
  .Scanner-Error a {
    color: #fff; }

.Scanner-visiblity-button {
  background: transparent;
  border: none;
  font-size: 2.4rem;
  padding: 2px;
  position: absolute;
  right: 3px;
  top: 3px; }
  .Scanner-visiblity-button:hover {
    background: transparent; }
