.FrontPage-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 100vh; }

.ShopLogo {
  display: block;
  margin: 0 auto 40px auto;
  max-width: 200px;
  height: auto; }

.SiteTitle {
  text-align: center;
  margin: 0 40px 0 40px; }

.SiteIntro {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-gap: 20px;
  max-width: 800px;
  margin: 40px auto 60px auto; }
  .SiteIntro + h2 {
    margin: 60px 0 30px 0;
    text-align: center; }

.SiteIntro-part {
  text-align: left; }
  .SiteIntro-part h2 {
    margin-bottom: 15px; }
  .SiteIntro-part.lang-se {
    text-align: right; }

.FrontPage-title {
  margin-bottom: 10px;
  text-align: center; }
  .FrontPage-title + .FrontPage-title {
    margin-bottom: 30px; }

.PagesList {
  display: grid;
  grid-template-columns: repeat(3, 230px);
  grid-gap: 40px;
  justify-content: center;
  margin-top: 60px; }

.PageItem {
  text-align: center; }
  .PageItem a {
    text-decoration: none; }
  .PageItem p {
    margin: 15px 0 20px 0; }

.PageItem-title {
  transition: transform 100ms ease-out, opacity 100ms linear;
  margin-top: 25px; }
  .PageItem-button:active .PageItem-title {
    opacity: 0.9;
    transform: translateY(15px); }

.PageItem-circle {
  background: #2a2a29;
  border-radius: 100%;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  justify-items: center;
  transition: transform 100ms ease-out, opacity 100ms linear;
  height: 230px;
  width: 230px; }
  .PageItem-button:active .PageItem-circle {
    opacity: 0.9;
    transform: translateY(15px); }

.PageItem-button {
  background: transparent;
  border: none;
  border-radius: 100%;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  justify-items: center;
  transition: transform 100ms ease-out, background-color 100ms linear;
  /*&:focus {
    background: green;
  }

  &:focus-within {
    background: purple;
  }*/
  /*&:hover {

  }*/ }
  .PageItem-button .icon {
    font-size: 12rem;
    pointer-events: none; }

@keyframes pulsyBorder {
  0% {
    border-color: #bdbcbc; }
  50% {
    border-color: rgba(187, 19, 44, 0.5); }
  100% {
    border-color: #bdbcbc; } }

.PagesList-intro {
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase; }
