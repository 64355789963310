.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: all 500ms linear; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0;
  transition: all 500ms linear; }
