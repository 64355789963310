.button,
button,
a.button {
  background: #1d1d1b;
  border: 1px solid #bdbcbc;
  border-radius: 10px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 150ms ease; }
  .button:hover,
  button:hover,
  a.button:hover {
    background-color: #fff;
    color: #1d1d1b; }
  .button[disabled],
  button[disabled],
  a.button[disabled] {
    opacity: 0.5; }
