.AppHeader {
  background: rgba(29, 29, 27, 0.75);
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000; }

.AppHeader-container {
  padding: 20px 0; }

.AppLogo {
  height: 40px;
  width: 290px; }
  .AppLogo a {
    text-decoration: none; }
  .AppLogo img {
    height: 40px;
    width: 290px; }

.AppNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px; }
  .AppNavigation .Menu:last-child {
    justify-content: flex-end; }

.Menu {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }

.Menu-item a {
  background: transparent;
  border: 1px solid #bdbcbc;
  border-radius: 3px;
  color: #1d1d1b;
  padding: 10px;
  transition: 300ms ease all;
  text-decoration: none; }
  .Menu-item a:hover {
    background-color: #fff;
    color: #fff; }

.Menu-item + .Menu-item {
  margin-left: 10px; }

.Menu-item-icon a {
  background: #fff;
  border-radius: 3px;
  border: none;
  color: #1d1d1b;
  display: flex;
  text-decoration: none;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px;
  text-align: center;
  transition: 300ms ease all;
  justify-content: center; }
  .Menu-item-icon a:hover {
    background: #2a2a29;
    color: #fff; }

.Menu-item-icon .icon {
  font-size: 1.6em;
  margin-right: 5px; }
