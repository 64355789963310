.ProductItem-title,
.ProductItem-no-title {
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.3rem;
  line-height: 1.3; }

.ProductItem-title {
  margin-top: 10px; }

.ProductItem-no-title {
  font-style: italic;
  margin: 5px 0; }

.ProductItem {
  overflow: hidden;
  position: relative; }
  .ProductItem a {
    text-decoration: none; }
  .ProductItem .LazyLoad {
    background: #fff;
    position: relative;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden; }
    .ProductItem .LazyLoad img {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
      height: auto;
      width: 100%;
      z-index: 100; }

.ProductItem-more {
  color: #f47c5e;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 6px; }
  .ProductItem-more .icon-price-tag {
    display: inline-block;
    fill: #f47c5e;
    color: #f47c5e;
    margin-right: 5px; }
  .ProductItem-more span {
    border-bottom: 1px solid #f47c5e; }

.ProductItem-news {
  position: absolute;
  top: -1px;
  left: -1px;
  height: auto;
  max-width: 40%;
  pointer-events: none;
  z-index: 1000; }

.ProductItem-flag {
  flex: 0 0 1em;
  height: 1em;
  width: 1em;
  margin: 0 5px 0 0; }
